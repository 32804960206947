export interface ENV_CONFIG {
  NAME: string;
  VERSION: string;
  API_BASE: string;
  PLUGIN_URL: string;
  CDN_URL: string;
  WEBSHOP_DEMO_URL: string;
  ANALYTICS_GOOGLE_TRACKING_ID?: string;
  PORT?: number;
  ENABLE_DEV_TOOLS: boolean;
  LOCAL_STORAGE_ID: string;
  DEFAULT_CURRENCY: string;
  DEFAULT_LOCALE: string;
  DEFAULT_LOCALE_DATE: string;
  SENTRY: {
    dsn: string;
    environment?: string;
    release?: string;
  };
}

const config: ENV_CONFIG = {
  // general
  NAME: process.env.REACT_APP_NAME || '',
  VERSION: process.env.REACT_APP_VERSION || '',

  // api
  API_BASE: process.env.REACT_APP_API_BASE || '',

  PLUGIN_URL: process.env.REACT_APP_PLUGIN_URL || '',
  CDN_URL: process.env.REACT_APP_CDN_URL || '',
  WEBSHOP_DEMO_URL: process.env.WEBSHOP_DEMO_URL || '',

  // analytics
  // ANALYTICS_GOOGLE_TRACKING_ID:
  //   process.env.REACT_APP_ANALYTICS_GOOGLE_TRACKING_ID || '',

  // error tracking
  SENTRY: {
    dsn:
      'https://492b636d4ca245ce805bdd597da32b3e@o190235.ingest.sentry.io/5414560',
    environment: process.env.REACT_APP_BUILD_ENV,
    release:
      process.env.REACT_APP_BUILD_ENV === 'production'
        ? `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
        : `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_BUILD_ENV}`,
  },

  // dev
  PORT: Number(process.env.REACT_APP_PORT ?? 3000),
  ENABLE_DEV_TOOLS: Boolean(process.env.REACT_APP_ENABLE_DEV_TOOLS ?? true),
  LOCAL_STORAGE_ID: process.env.REACT_APP_LOCAL_STORAGE_ID ?? '',
  DEFAULT_CURRENCY: process.env.REACT_APP_DEFAULT_CURRENCY ?? 'EUR',
  DEFAULT_LOCALE: process.env.REACT_APP_DEFAULT_LOCALE ?? 'en-GB',
  DEFAULT_LOCALE_DATE: process.env.REACT_APP_DEFAULT_LOCALE_DATE ?? 'nl',
};

export default config;
