import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import {
  IFRAME_ID,
  IFRAME_URL,
  SHAV_WRAPPER_ID,
} from '../src/shared/constants';
import { SharedEvents } from '../src/shared/events';
// import { initEventListeners } from './events';
import { iFrameRemoveStyle, iFrameStyle, wrapperStyle } from './shim.style';

export const CHANGE_CONTAINER_CLASS = 'CHANGE_CONTAINER_CLASS';
export const CHANGE_CONTAINER_CLASS_DONE = 'CHANGE_CONTAINER_CLASS_DONE';

export const SET_DEBUG_MODE = 'SET_DEBUG_MODE';

export const DOMAIN_NOT_ALLOWED = 'DOMAIN_NOT_ALLOWED';

export const BOOTSTRAP_DONE = 'BOOTSTRAP_DONE';

declare global {
  interface Window {
    shavatar: Shavatar;
  }
}

export interface ShavatarOptions {
  productId: string | number;
  debug?: boolean;
  enableAnalytics?: boolean;
  noCupSize?: boolean;
  privacyPolicyUrl?: string;
  privacyPolicyShopName?: string;
  language?: string;
  privacyPolicyUrl?: string;
  privacyPolicyShopName?: string;
  addToCartFunc?: (variant?) => void;
  onGoToCart?: () => void;
  colors: {
    primary: string;
  };
  productVariants: {
    variantId: string | number;
    inventory_quantity: number | undefined;
    optionsList: string | undefined;
  }[];
}

class Shavatar {
  private debugMode: boolean;
  private domainAllowed: boolean;
  private iframe?: HTMLIFrameElement = undefined;
  public clientId: string = '';
  public options: ShavatarOptions;
  public onload: () => void;
  public awaitConsent: boolean = false;

  constructor(onloadFunc = () => {}) {
    this.debugMode = true;
    this.onload = onloadFunc;
    this.domainAllowed = true; // optimistically assume for now
  }

  // PUBLIC API

  init = (clientId: string, options: ShavatarOptions) => {
    this.logDebugMessage('init widget');
    this.clientId = clientId;
    this.options = options;
    if (!clientId) {
      console.error(
        'no client id: please provide a valid client id to initialize the flow',
      );
    }
    if (!options.productId) {
      console.error(
        'no product id: please provide a valid product id to initialize the flow',
      );
      return;
    }
    this.initializeIframe();
    this.mountIframe();
    if (options?.debug) {
      this.debug();
    }
    this.onload();
  };

  debug = () => {
    this.debugMode = !this.debugMode;
    console.info(
      `[SHAVATAR] debug mode ${this.debugMode ? 'enabled' : 'disabled'}`,
    );
    this.iframe?.contentWindow?.postMessage(
      { type: SET_DEBUG_MODE, value: this.debugMode },
      '*',
    );
  };

  // PRIVATE METHODS

  onCloseWidget = () => {
    this.logDebugMessage('Closing widget');

    if (this.iframe) {
      enableBodyScroll(this.iframe);
    }
    document
      .getElementById(SHAV_WRAPPER_ID)
      .setAttribute('style', iFrameRemoveStyle);
  };

  onCloseWidgetAddToCart = (variant) => {
    this.logDebugMessage('Adding to cart');
    if (
      this.options?.addToCartFunc != undefined &&
      typeof this.options?.addToCartFunc === 'function'
    )
      this.options.addToCartFunc(variant);
  };

  onGoToCart = () => {
    this.logDebugMessage('Going to cart');
    if (
      this.options?.onGoToCart != undefined &&
      typeof this.options?.onGoToCart === 'function'
    )
      this.options.onGoToCart();
  };

  receiveMessage = (event: MessageEvent) => {
    this.logDebugMessage('received message with data:', event);

    if (!!event && !!event.data && !!event.data.type) {
      switch (event.data.type) {
        case SharedEvents.JS_LOADED:
          this.iframe.contentWindow.postMessage(
            {
              type: SharedEvents.INIT_WIDGET,
              value: {
                widget: 'sizeAdvice',
                clientId: this.clientId,
                topHost: window.location.host,
                options: {
                  ...this.options,
                  addToCartFunc: undefined,
                  onGoToCart: undefined,
                },
              },
            },
            '*',
          );
          break;
        // case SharedEvents.SET_COOKIE:
        //   document.cookie = event.data.value;
        //   break;
        case SharedEvents.CLOSE_WIDGET:
          this.onCloseWidget();
          break;
        case SharedEvents.ADD_TO_CART:
          this.onCloseWidgetAddToCart(event.data?.payload?.selectedVariant);
          break;
        case SharedEvents.CART:
          this.onGoToCart();
          break;
        case SharedEvents.ABOUT_YOU:
          console.log('ABOUT_YOU');
          break;
        case SharedEvents.MEASUREMENTS:
          console.log('MEASUREMENTS');

          break;
        case SharedEvents.RECOMMENDATION:
          console.log('RECOMMENDATION');

          break;
        case BOOTSTRAP_DONE:
          this.handleBootstrapDone();
          break;
        default:
          break;
      }
    }
  };

  initializeIframe = () => {
    this.logDebugMessage('Initializing iframe');

    console.log('within initializeIframe');
    if (!document.getElementById(IFRAME_ID)) {
      this.logDebugMessage('iframe does not exist, creating...');
      const iframe = document.createElement('iframe');
      iframe.src = `${IFRAME_URL}?clientId=${this.clientId}`;
      iframe.id = IFRAME_ID;
      iframe.setAttribute('style', iFrameStyle);

      this.iframe = iframe;
      console.log('finished no IFRAME_ID');
    } else {
      console.log('has IFRAME_ID');
      this.logDebugMessage('iframe does exist, posting init message');
      this.iframe?.contentWindow?.postMessage(
        {
          type: SharedEvents.INIT_WIDGET,
          value: {
            widget: 'sizeAdvice',
            clientId: this.clientId,
            topHost: window.location.host,
            options: {
              ...this.options,
              addToCartFunc: undefined,
              onGoToCart: undefined,
            },
          },
        },
        '*',
      );
      console.log('did init');
    }

    if (this.iframe) {
      disableBodyScroll(this.iframe);
    }
  };

  mountIframe = () => {
    this.logDebugMessage('mounting iframe to HTML DOM');

    let wrapper = document.getElementById(SHAV_WRAPPER_ID);
    if (!document.getElementById(IFRAME_ID) && !wrapper && this.iframe) {
      this.logDebugMessage('attaching "message" event listener to window');
      window.addEventListener('message', this.receiveMessage, false);
      this.logDebugMessage('creating wrapper div');
      const wrapper = document.createElement('div');
      wrapper.id = SHAV_WRAPPER_ID;
      wrapper.style.zIndex = '1200';
      wrapper.setAttribute('style', wrapperStyle);
      this.logDebugMessage('adding iframe to wrapper');
      wrapper.appendChild(this.iframe);
      this.logDebugMessage('adding wrapper to DOM');
      document.body.appendChild(wrapper);
    } else {
      this.logDebugMessage('wrapper and iframe already exist in HTML DOM');
      wrapper.setAttribute('style', wrapperStyle);
    }
  };

  // DEBUG
  handleBootstrapDone = () => {
    this.logDebugMessage('bootstrap done');

    const shavatarApi = window.shavatar;
    shavatarApi.debug = this.debug;
    window.shavatar = shavatarApi;
  };

  logDebugMessage = (...args) => {
    if (this.debugMode) {
      console.log('[SHAVATAR]: ', ...args);
    }
  };
}

export default ((win: unknown) => {
  const window = win as Window;
  const onloadFunc =
    window.shavatar?.onload && typeof window.shavatar.onload === 'function'
      ? window.shavatar.onload
      : () => {};
  const shavatar = new Shavatar(onloadFunc);
  window.shavatar = shavatar;
})(global);
