export const JS_LOADED = 'widget/js-loaded';
export const INIT_WIDGET = 'widget/init';
export const CLOSE_WIDGET = 'widget/close';
export const ADD_TO_CART = 'widget/add';
export const CART = 'widget/cart';
export const SET_COOKIE = 'SET_COOKIE';
export const ABOUT_YOU = 'ABOUT_YOU';
export const MEASUREMENTS = 'MEASUREMENTS';
export const RECOMMENDATION = 'RECOMMENDATION';
export const START_FLOW = 'START_FLOW';

export const SharedEvents = {
  JS_LOADED,
  INIT_WIDGET,
  CLOSE_WIDGET,
  ADD_TO_CART,
  CART,
  SET_COOKIE,
  START_FLOW,
  ABOUT_YOU,
  MEASUREMENTS,
  RECOMMENDATION,
};
