export const wrapperStyle = `
  z-index: 1200;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  display: block;
  background-color: rgba(0,0,0, 0.2);
`;

export const iFrameStyle = `
  width: 100%;
  height: 100%;
  border: 0;
`;

export const iFrameRemoveStyle = `
  display: none;
`;
